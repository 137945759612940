<template>
    <div>
        <div class="bread_crumb w1703">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'list',query:{classcode:item.key} }"
                    v-for="(item,index) in info.crumbs" :key="index">{{item.value}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{info.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="common-view w1703">
            <div class="main-detail" v-if="Object.keys(info).length>0">
                <div class="detail-l">
                    <div class="detail-carousel">
                        <div class="swiper">
                            <div class="swiper-container" ref="productSwiper">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide swiper-no-swiping" v-for="(carousel,index) in bannerList"
                                        :key="index">
                                        <el-image
                                            style="width: auto; height: auto;max-width:100%;max-height:100%;cursor: zoom-in;opacity:0;position:absolute;top:0;left:0;bottom:0;right:0;"
                                            :src="carousel" :preview-src-list="bannerList" :fit="'cover'"></el-image>
                                        <img :src="carousel" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="spec-items">
                        <div class="arr-btn left-btn" @click="mySwiper.slidePrev()">
                            <i class="el-icon-arrow-left"></i>
                        </div>
                        <div class="content">
                            <ul>
                                <li :class="mySwiper.activeIndex == index ? 'active':''"
                                    v-for="(item,index) in bannerList" :key="index" @click="mySwiper.slideTo(index)">
                                    <div class="preview">
                                        <img :src="item" alt="">
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="arr-btn right-btn" @click="mySwiper.slideNext()">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="detail-r">
                    <div class="r_t">
                        <div class="detail_title">
                            <h1>{{info.title}}</h1>
                        </div>
                        <div class="view_count" v-if="false">
                            <p>
                                <i class="icon-model-view"></i>1419
                            </p>
                            <p>
                                <i class="icon-model-collect"></i>19
                            </p>
                        </div>
                        <div class="model-price-main">
                            <!-- <div class="model-price">
                                <p class="old-price">
                                    <b>24</b>币
                                </p>
                                <p class="vip-price">
                                    <i></i>
                                    
                                    <em>会员</em>:
                                    <b>16</b>
                                    币
                                </p>
                            </div> -->
                            <div class="model-price">
                                <p class="old-price">
                                    <b>{{info.price}}</b>元
                                </p>
                            </div>
                        </div>
                        <div class="download_btn" @click="downLoad">
                            <p>立即下载</p>
                        </div>
                        <div class="become_v">
                            <a @click="Link('vip',{},true)">开通vip</a>
                            <p @click="collect">
                                <van-icon :name="info.collect==0?'star-o':'star'"
                                    :color="info.collect==0?'#666':'#e80000'" size="14" />
                                {{info.collect==0?'收藏':'已收藏'}}
                            </p>
                        </div>
                    </div>
                    <div class="r_c">
                        <div class="model-tag">
                            <div class="model-tag-item" v-for="(item,index) in info.data" :key="index">
                                <h5>{{item.key}}</h5>
                                <p>{{item.value}}</p>
                            </div>
                        </div>
                        <div class="author" v-if="false">
                            <div>
                                <a href="">
                                    <img src="https://down.om.cn/v3/headpic/2021-12-31/B8HR8pynSt4844CbpMyMZCb8ampWGrnC.jpg"
                                        alt="">
                                </a>
                                <p class="producer_nickname">张三一号</p>
                            </div>
                            <!-- <button>
                                关注
                            </button> -->
                        </div>
                        <div class="next_prev" v-if="false">
                            <a href="">
                                <span>上一个：</span>侘寂风奶油风客餐厅su模型
                            </a>
                            <a href="">
                                <span>下一个：</span>现代客厅餐厅su模型
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="common_detail_recommend" v-if="Object.keys(info).length>0">
                <div class="title">
                    <div class="common_title_style">相关推荐</div>
                </div>
                <div class="common_main_content ">
                    <ul>
                        <li v-for="(item) in info.relation" :key="item.id">
                            <router-link :to="{name:'listDetail',query:{id:item.id}}" class="detail_img">
                                <img :src="item.logo" alt="">
                            </router-link>
                            <div class="detail_dec">
                                <p class="detail_dec_info">
                                    <router-link :to="{name:'listDetail',query:{id:item.id}}"> {{item.title}}
                                    </router-link>
                                    <!-- <span>ID:10004749</span> -->
                                </p>
                                <!-- <div class="detail_dec_icon">
                                    
                                    <div class="detail_add">
                                        <span>收藏</span>
                                        <span>下载</span>
                                    </div>
                                </div> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <el-dialog width="300px" :visible.sync="dialogFormVisible" @close="close" @open="open"
            :close-on-click-modal="false" style="margin-top:10vh;">
            <div class="pay_tit" slot="title">请使用微信扫码</div>
            <div class="pay_con">
                <img :src="payImg" alt="">
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Swiper from "swiper";
    import {
        getMeatDetailById,
        getMeatLocationById,
        getMeatRealLocationById,
        getOrderPayStatus,
        getCollectionMeta
    } from '@/api/index.js';
    export default {
        data() {
            return {
                mySwiper: null,
                info: {},
                bannerList: [],
                dialogFormVisible: false,
                payImg: '',
                btn_c: true,
                timer: 0,
            }
        },
        methods: {
            getInfo() {
                getMeatDetailById({
                    id: this.$route.query.id
                }).then(r => {
                    this.info = r;
                    this.bannerList = r.image;
                    console.log(this.bannerList)
                })
            },
            downLoad() {
                if (!this.btn_c) return false;
                this.btn_c = false;
                getMeatLocationById({
                    id: this.info.id,
                    type: 'native'
                }).then(r => {
                    switch (r.status) {
                        case 0:
                            this.$confirm(r.info, '提示', {
                                confirmButtonText: '立即下载',
                                cancelButtonText: '取消',
                                closeOnClickModal: false,
                                type: 'info'
                            }).then(() => {
                                this.realDownLoad();
                            }).catch(() => {
                                console.log('取消');
                            });
                            break;
                        case 1:
                            this.$confirm(r.info, '提示', {
                                confirmButtonText: '立即购买',
                                cancelButtonText: '取消',
                                closeOnClickModal: false,
                                type: 'info'
                            }).then(() => {
                                //
                                this.payImg = r.image;
                                this.dialogFormVisible = true;
                                this.checkResponse(r.order_no, r.chk_type);
                                console.log('确定');
                            }).catch(() => {
                                console.log('取消');
                            });
                            break;
                        case 2:
                            this.$confirm(r.info, '提示', {
                                confirmButtonText: r.type == 1 ? '立即下载' : '立即购买',
                                cancelButtonText: '取消',
                                closeOnClickModal: false,
                                type: 'info'
                            }).then(() => {
                                if (r.type == 1) {
                                    this.realDownLoad();
                                } else if (r.type == 0) {
                                    this.payImg = r.image;
                                    this.dialogFormVisible = true;
                                }
                                console.log('确定');
                            }).catch(() => {
                                console.log('取消');
                            });
                            break;
                        default:
                            return false;
                    }
                    this.btn_c = true;
                    // if (r.info) {
                    //     this.$confirm(r.info, '提示', {
                    //         confirmButtonText: '确定',
                    //         cancelButtonText: '取消',
                    //         type: 'info'
                    //     }).then(() => {
                    //         console.log('确定');
                    //     }).catch(() => {
                    //         console.log('取消');
                    //     });
                    // }

                    // window.location.href = r;
                })
            },
            realDownLoad() {
                getMeatRealLocationById({
                    id: this.info.id
                }).then(r => {
                    if (r.download_url != '') {
                        window.location.href = r.download_url;
                    }
                })
            },
            checkResponse(order_no, chk_type) {
                let ttt = setInterval(() => {
                    getOrderPayStatus({
                        order_no: order_no,
                        type: chk_type
                    }).then(r => {
                        window.location.reload();
                    }).catch(err => {
                        if (err == 3) {
                            clearInterval(ttt);
                            this.timer = 1;
                        }
                    })
                    if (this.timer == 1) {
                        clearInterval(ttt)
                    }
                }, 1000);
            },
            close() {
                this.timer = 1;
            },
            open() {
                this.timer = 0;
            },
            collect() {
                if (this.info.collect == 1) return false;
                getCollectionMeta({
                    id: this.info.id
                }).then(r => {
                    this.info.collect = 1;
                    this.$forceUpdate();
                })
            }
        },
        mounted() {

        },
        created() {
            this.getInfo();
        },
        watch: {
            bannerList: {
                immediate: true,
                handler() {
                    let that = this;
                    this.$nextTick(() => {
                        this.mySwiper = new Swiper(this.$refs.productSwiper, {
                            // loop: false,
                            // autoplay: {
                            //   disableOnInteraction: false,
                            // },
                            noSwiping: true,
                        });
                        console.log(this.mySwiper);
                    });
                },
            },
            '$route'() {
                this.getInfo();
            },
        },
    };
</script>

<style lang="less" scoped>
    .bread_crumb {
        display: flex;
        align-items: center;
        max-width: 1500px;
        height: 58px;
        color: #bbb;

        p {
            color: #bbb;
            font-size: 14px;

            a {
                color: inherit;
            }
        }
    }

    .common-view {
        max-width: 1500px;
        min-height: 900px;

        .main-detail {
            display: flex;
            background-color: #fff;

            .detail-l {
                position: relative;
                width: 1140px;
                margin-bottom: 20px;
                background-color: #fff;
                margin-right: 20px;

                .detail-carousel {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: 700px;

                    .swiper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        height: 700px;
                        border-bottom: 1px solid #eee;

                        img {
                            height: auto;
                            width: auto;
                            max-width: 100%;
                            max-height: 100%;
                            cursor: zoom-in;
                        }
                    }
                }

                .spec-items {
                    position: relative;
                    display: flex;
                    // justify-content: center;
                    padding-left: 40px;
                    padding-right: 40px;
                    align-items: center;
                    // width: 840px;
                    // height: 180px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #eee;

                    .arr-btn {
                        position: absolute;
                        top: 18px;
                        width: 40px;
                        height: 140px;
                        cursor: pointer;
                        z-index: 10;
                        line-height: 150px;
                        background-color: #fff;
                        text-align: center;
                        z-index: 7;

                        i {
                            font-size: 20px;

                        }
                    }

                    .left-btn {
                        left: 0;

                        // i {
                        //     background-position: -327px -200px;
                        // }
                    }

                    .right-btn {
                        right: 0;

                        // i {
                        //     background-position: -358px -200px;
                        // }
                    }

                    .content {
                        width: 100%;
                        position: relative;
                        // height: 140px;
                        // overflow: hidden;
                        margin: 0 2px;

                        ul {
                            // position: absolute;
                            width: 100%;
                            // left: 0;
                            // overflow: hidden;
                            display: flex;
                            flex-wrap: wrap;

                            li {
                                // float: left;
                                cursor: pointer;
                                width: 150px;
                                height: 140px;
                                flex-shrink: 0;
                                border-radius: 5px;

                                .preview {
                                    width: 140px;
                                    height: 100%;

                                    img {
                                        max-width: 100%;
                                        max-height: 100%;
                                        height: 140px;
                                        object-fit: cover;
                                        border: 2px solid transparent;
                                        border-radius: 5px;
                                    }
                                }
                            }

                            li:last-child {
                                margin-right: 0;
                            }

                            li.active .preview {
                                img {
                                    border: 2px solid #e80000;
                                }
                            }
                        }
                    }
                }
            }

            .detail-r {
                position: relative;
                width: 340px;
                padding-right: 20px;

                .r_t {
                    .detail_title {
                        padding-top: 24px;
                        margin-bottom: 18px;

                        h1 {
                            font-size: 18px;
                            line-height: 30px;
                            font-family: MicrosoftYaHei-Bold;
                            display: inline-block;
                            font-weight: 600;
                        }

                        p {
                            font-size: 18px;
                            line-height: 30px;
                            font-family: MicrosoftYaHei-Bold;
                            display: inline-block;
                            font-weight: 600;
                        }
                    }

                    .view_count {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #eee;

                        p {
                            display: flex;
                            align-items: center;
                            height: 15px;
                            color: #999;
                            line-height: 15px;
                            font-size: 14px;

                            i {
                                display: inline-block;
                                background-repeat: no-repeat;
                                background-image: url(https://down.om.cn/common/icon_utils_v1.png);
                                width: 18px;
                                height: 18px;
                                margin-right: 5px;
                            }

                            .icon-model-view {
                                background-position: -327px -174px;

                            }

                            .icon-model-collect {
                                background-position: -351px -174px;
                            }
                        }
                    }

                    .model-price-main {
                        width: 100%;
                        margin: 26px 0;
                        position: relative;

                        .model-price {
                            flex-direction: row-reverse !important;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .old-price {
                                padding-right: 0;
                                padding-left: 16px;
                                color: #333;
                                font-size: 14px;
                                line-height: 18px;
                                position: relative;

                                b {
                                    font-size: 24px;
                                    line-height: 18px;
                                    margin-right: 4px;
                                    font-family: Arial-BoldMT;
                                }
                            }

                            .vip-price {
                                position: relative;
                                padding-left: 0;
                                padding-right: 16px;
                                font-size: 14px;
                                line-height: 18px;
                                color: #f90;

                                // i {
                                //     display: inline-block;
                                //     background-repeat: no-repeat;
                                //     background-image: url(https://down.om.cn/common/icon_utils_v1.png);
                                //     width: 16px;
                                //     height: 16px;
                                //     margin-bottom: -3px;
                                //     margin-right: 2px;
                                //     background-position: -501px -202px;
                                // }

                                b {
                                    font-size: 24px;
                                    line-height: 18px;
                                    margin-right: 4px;
                                    font-family: Arial-BoldMT;
                                }

                                em {
                                    font-style: normal;
                                    text-decoration: underline;
                                    cursor: pointer;
                                    color: #f90;
                                }

                                &::after {
                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    content: "/";
                                    color: #bbb;
                                }
                            }
                        }
                    }

                    .download_btn {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 320px;
                        background-color: #e80000;
                        margin-bottom: 18px;
                        height: 60px;
                        cursor: pointer;
                        border-radius: 5px;
                        color: #fff;

                        p {
                            font-size: 18px;
                        }
                    }

                    .become_v {
                        width: 320px;
                        height: 44px;
                        border-radius: 5px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                        font-size: 12px;
                        margin-bottom: 18px;

                        a {
                            display: flex;
                            align-items: center;
                            color: #e80000;
                            text-decoration: underline;
                            text-underline-position: under;
                        }

                        p {
                            color: #666;
                            cursor: pointer;
                            display: flex;
                            align-items: center;


                        }
                    }
                }

                .r_c {
                    .model-tag {
                        position: relative;
                        line-height: 1;
                        padding: 25px 20px 0;
                        border: 1px solid #eee;
                        border-radius: 5px;
                        margin-bottom: 18px;

                        .model-tag-item {
                            width: 100%;
                            display: flex;
                            margin-bottom: 30px;
                            align-items: center;

                            h5 {
                                position: relative;
                                flex-shrink: 0;
                                width: 60px;
                                text-align: justify;
                                text-align-last: justify;
                                color: #999;
                                font-size: 14px;
                                font-weight: 500;
                                margin-right: 14px;

                                &::after {
                                    position: absolute;
                                    right: -4px;
                                    top: 0;
                                    content: ":";
                                    display: inline-block;
                                    color: #999;
                                }
                            }

                            p {
                                flex-shrink: 0;
                                font-size: 14px;
                                color: #333;

                                b {
                                    display: inline-block;
                                    padding-left: 5px;
                                    padding-right: 5px;
                                    height: 20px;
                                    line-height: 18px;
                                    text-align: center;
                                    border-radius: 3px;
                                    border: 1px solid #e80000;
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #e80000;
                                    font-family: MicrosoftYaHei;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    .author {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        div {
                            display: flex;
                            align-items: center;
                            height: 60px;

                            img {
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                margin-right: 10px;
                            }

                            .producer_nickname {
                                max-width: 146px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        button {
                            border: 1px solid #666;
                            border-radius: 3px;
                            width: 80px;
                            height: 32px;
                            line-height: 30px;
                            text-align: center;
                            background-color: #fff;
                            // margin-top: 5px;
                            color: #666;
                            outline: none;
                            cursor: pointer;
                        }
                    }

                    .next_prev {
                        display: flex;
                        padding: 0 14px;
                        width: 320px;
                        height: 44px;
                        border-radius: 5px;
                        border: 1px solid #eee;
                        color: #999;
                        margin-top: 18px;
                        margin-bottom: 18px;
                        font-size: 14px;

                        a {
                            width: 136px;
                            line-height: 44px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-right: 20px;
                            color: #999;
                        }

                        a:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .pay_con {
        width: 100%;
        height: 280px;
        background-color: #fff;
        font-size: 16px;

        .tit {
            font-weight: bold;
        }

        img {
            max-width: 100%;
        }
    }

    .common_detail_recommend {
        margin-top: 30px;

        .title {
            position: relative;
            margin-bottom: 20px;

            .common_title_style {
                position: relative;
                color: #333;
                font-size: 24px;
            }
        }

        .common_main_content {
            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    position: relative;
                    height: 100%;
                    width: 280px;
                    display: flex;
                    flex-direction: column;
                    margin: 0 25px 25px 0;
                    border-radius: 5px;
                    transition: transform .3s linear;

                    .detail_img {
                        position: relative;
                        width: 100%;
                        height: 285px;
                        text-align: center;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;

                        img {
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            transition: opacity .3s linear;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .detail_dec {
                        // height: 80px;
                        background-color: #fff;
                        padding: 15px 14px 0;
                        line-height: 1;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;

                        .detail_dec_info {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: 28px;
                            // border-bottom: 1px solid #eee;
                            padding-bottom: 12px;

                            a {
                                color: #666;
                                width: 65%;
                                font-size: 14px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            span {
                                color: #666;
                                font-size: 14px;
                            }
                        }

                        .detail_dec_icon {
                            display: flex;
                            font-size: 12px;
                            height: 40px;
                            color: #bbb;

                            .detail_add {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                span {
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                li:nth-child(5n) {
                    margin-right: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 1500px) {
        .common-view .main-detail .detail-l {
            width: 840px;
        }

        .common_detail_recommend .common_main_content ul li {
            width: 220px;

            .detail_img {
                height: 220px;
                width: 220px;
            }

            .detail_dec {
                padding: 10px 10px 0;
                height: 70px;
                width: 220px;

                .detail_dec_info {
                    a {
                        font-size: 12px;
                    }

                    span {
                        font-size: 12px;
                    }
                }

                .detail_dec_icon {
                    height: 30px;
                }
            }
        }
    }

    // @media only screen and (max-width: 1000px) {
    //     .common-view .main-detail {
    //         flex-wrap: wrap;

    //         .detail-l {
    //             width: 100%;
    //             margin-right: 0;

    //             .detail-carousel {
    //                 height: auto;

    //                 .swiper {
    //                     height: auto;
    //                 }
    //             }

    //             .spec-items {
    //                 width: 100%;
    //                 padding-left: 40px;
    //                 padding-right: 50px;

    //                 .content {
    //                     width: 100%;
    //                     overflow-x: scroll;
    //                     height: auto;

    //                     ul {
    //                         position: relative;
    //                         width: auto;

    //                         li {
    //                             width: 100px;
    //                             height: 96px;

    //                             .preview {
    //                                 width: 100%;
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         .detail-r {
    //             width: 100%;
    //             padding-left: 20px;

    //             .r_t .download_btn {
    //                 width: 100%;
    //             }
    //         }
    //     }

    //     .common_detail_recommend .common_main_content ul {
    //         li {
    //             width: calc(50% - 5px);
    //             margin-right: 10px;
    //             margin-bottom: 10px;

    //             .detail_img {
    //                 width: 100%;
    //                 height: auto;
    //             }

    //             .detail_dec {
    //                 width: 100%;
    //             }
    //         }

    //         li:nth-child(5n) {
    //             margin-right: 10px;
    //         }

    //         li:nth-child(2n) {
    //             margin-right: 0;
    //         }
    //     }
    // }
</style>